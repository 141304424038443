
/**** Common ************************************************/
* {max-height:1000000px;margin:0;padding:0;}
img, fieldset  {border:none;}
hr, button img {display:none;}
input, select, button, textarea {font-family:'Noto Sans Korean', 'Noto Sans KR', 'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim,Tahoma,Verdana,Geneva,sans-serif,Apple Gothic,AppleGothic;font-size:15px;font-weight:400;color:#505050;line-height:30px;letter-spacing:-0.03em;box-sizing:border-box;vertical-align:middle;}
input[type=submit] {cursor:pointer;appearance:none;-moz-appearance:none;-webkit-appearance:none;border-radius:0;-moz-border-radius:0;-webkit-border-radius:0;}
ul, li {list-style:none;}
a {display:block;font-weight:400;color:#505050;text-decoration:none}
a:hover, a:active, a:focus, a:visited {text-decoration:none;}
textarea {overflow:auto;border-radius:5px;border-color:#bdbdbd;}


form  {margin:0;padding:0;}
img {width:auto;max-width:100%;vertical-align:top;}
table img {width:auto;vertical-align:middle;}
legend  {position:absolute;left:0;top:0;width:0;height:0;overflow:hidden;visibility:hidden;font-size:0;line-height:0;} /* For Screen Reader */
caption {width:0;height:0;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999px;overflow:hidden;visibility:hidden;}
em {font-style:normal;}
p {display:block;}
.blind {position:absolute;left:-9999px;top:-9999px;}
.fl {float:left;}
.fr {float:right;}
.mc {margin:0 auto;}
.clear:after {content:"";display:block;clear:both;}
.fawb:before, .fawa:after {font-family:FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;}
body {font-family:'Noto Sans Korean','Noto Sans KR', 'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim,Tahoma,Verdana,Geneva,sans-serif,Apple Gothic,AppleGothic;font-size:13px;font-weight:400;color:#505050;line-height:30px; letter-spacing:-0.03em; -webkit-text-size-adjust:none;font-smoothing:antialiased;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}

/* 테이블 */
table {width:100%;table-layout:fixed;border-collapse:collapse;border-spacing:0;text-align:center;}
table img {vertical-align:top;}
th {vertical-align:middle;word-wrap:break-word;word-break:break-all; background-color: lightgray; }
td {vertical-align:middle;word-wrap:break-word;word-break:break-all; background-color: white; border-bottom: 1px solid lightgray; }

@media screen and (max-width: 766px )
{
th {  font: size 11px}
td {  border-bottom: 2px solid lightgray; font-size:11px }
}

#parent {
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.squireBox {
  border: solid 5px black;
  margin-bottom: 10px;
  padding: 15px;
  min-width: 700px;
}

/*
.main_container {
  position: fixed;
  
  width: calc(100% - 60px);
  height: calc(100% - 45px);


  background-color: rgb(243, 243, 245);
  float: right;
  top: 45px;
  left: 60px;;

  overflow: scroll;
}
*/
.main_container {
  width:100%;
  height:calc(100% - 120px);
  position: fixed;
  background-color: white ; 
  float: right;
  overflow: scroll;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* 로그인 창 */
.main{height: 600px; width: 100%; background-color:rgb(102, 118, 162); display: table; table-layout: fixed; margin:0 auto }
.m_login{display: table-cell; text-align: center; vertical-align: middle; }
.m_login h3{font-size: 55px; line-height: 67px; color: #fff; font-weight: 400; letter-spacing: 0; vertical-align: middle; margin-bottom: 39px;}
.m_login h3 span{margin-right: 17px;}

.log_box{width: 300px; margin: 0 auto;  }
.in_type{position: relative; background: #fff; width: 250px; height: 55px; display: block; margin: 0 auto; margin-bottom: 14px; text-align: left; transition:.3s; }
.in_type span{position: relative; width: 50px; height: 55px; transition:.3s; display: block; float: left;}
.in_type .icon {padding: 20px; transition:.3s;}
.in_type input{height: 50px; line-height: 50px; border: 0 !important; font-size: 16px; color: #9a9a9a; width: 200px; outline:none; padding-left: 18px;}
.in_type input::placeholder {color: #9a9a9a; font-size: 16px; line-height: 50px; }

.m_login .s_bt{position: relative; display:block ; margin:0 auto; background: #c79448; color: #f2f2f2; font-size: 20px; line-height: 65px; height: 65px; border: 0; width: 250px; transition:.3s;}
.m_login .s_bt:hover{background: #f3a721;}


@media screen and (min-width: 1024px )
{
/* 로그인 창 */
.main{width: 80%;}
.log_box{width: 400px; }
.in_type{width: 350px; }
.in_type input{ width: 300px;}
.m_login .s_bt{ width: 350px; transition:.3s;}
}

/* pagenation */ 
.pagination {
  display: flex;
  justify-content: center;
  margin-top :10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

ul.pagination li:first-child{
  border-radius: 15px 0 0 15px;
}

ul.pagination li:last-child{
  border-radius: 0 15px 15px 0;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

